import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { setPinRequest, updateNomineeRequest } from "../features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../features/appSlice";
import { clearHasError } from "../features/userSlice";
import SuccessModal from "../components/modals/SuccessModal";

const pinChangeSchema = Yup.object().shape({
  pin: Yup.string()
    .matches(/^\d{4}$/, "PIN must be a 4-digit number")
    .required("PIN is required"),
  password: Yup.string().required("Password is required"),
  repeat: Yup.string()
    .oneOf([Yup.ref("pin"), null], "PIN must match")
    .required("Confirm PIN is required"),
});

const SetPin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const successMessage = useSelector((state) => state.app.message);
  const errorMessage = useSelector((state) => state.user.hasError);
  const user = useSelector((state) => state.user.userDetails);

  const initialValues = {
    pin: "",
    repeat: "",
    password: "",
  };

  const handleSubmit = (values) => {
    dispatch(setPinRequest(values));
  };

  const successAndRedirect = () => {
    localStorage.removeItem("greedAppToken");
    dispatch(setMessage(""));
    navigate("/");
  };

  const errorAndStay = () => {
    dispatch(clearHasError());
  };

  return (
    <div className="max-w-md p-6 mx-auto mt-8 bg-white rounded-md ">
      <SuccessModal
        open={successMessage}
        clickFunction={successAndRedirect}
        text={successMessage}
      />
      <SuccessModal
        open={errorMessage}
        clickFunction={errorAndStay}
        text={errorMessage}
        error
      />
      <h2 className="mb-6 text-2xl font-semibold">Set Fund PIN</h2>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={pinChangeSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="mb-4">
            <label
              htmlFor="pin"
              className="block text-sm font-medium text-gray-600"
            >
              4 Digit PIN
            </label>
            <Field
              autoComplete="off"
              id="pin"
              name="pin"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="pin"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="repeat"
              className="block text-sm font-medium text-gray-600"
            >
              Repeat PIN
            </label>
            <Field
              autoComplete="off"
              id="repeat"
              name="repeat"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="repeat"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              Password
            </label>
            <Field
              autoComplete="off"
              type="password"
              id="password"
              name="password"
              placeholder="Please enter Password"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-400"
          >
            Set MY PIN Now
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default SetPin;
