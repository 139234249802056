import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TransactionTable from "../components/TransactionTable";
import { getPartnerTransactionsRequest } from "../features/userSlice";

const Timeline = () => {
  const dispatch = useDispatch();
  const myTransactionList = useSelector((state) => state.user.myTransactions);

  useEffect(() => {
    dispatch(getPartnerTransactionsRequest());
  }, [dispatch]);
  return (
    <>
      <h1 className="px-4 py-2 font-semibold text-gray-800">
        Transactions Timeline ({myTransactionList?.length})
      </h1>
      <TransactionTable transactions={myTransactionList} />
    </>
  );
};

export default Timeline;
