import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import DashboardLayout from "./components/layouts/dashboard";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import MainDashboard from "./pages/MainDashboard";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import Timeline from "./pages/Timeline";
import ChangePassword from "./pages/ChangePassword";
import SetPin from "./pages/SetPin";
import UpdateNominee from "./pages/UpdateNominee";
import WithdrawRequest from "./pages/WithdrawRequest";
import CloseAllTrades from "./pages/CloseAllTrades";

// Define the routes
const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "dashboard/*",
    element: <DashboardLayout />,
    children: [
      { path: "", element: <Navigate to="/dashboard/main" replace /> },
      {
        path: "main",
        element: <MainDashboard />,
      },
      {
        path: "deposits",
        element: <Deposit />,
      },
      {
        path: "withdrawls",
        element: <Withdraw />,
      },
      {
        path: "withdraw-request",
        element: <WithdrawRequest />,
      },
      {
        path: "timeline",
        element: <Timeline />,
      },
      {
        path: "close-all-trades",
        element: <CloseAllTrades />,
      },
      {
        path: "settings/change-password",
        element: <ChangePassword />,
      },
      {
        path: "settings/set-pin",
        element: <SetPin />,
      },
      {
        path: "settings/update-nominee",
        element: <UpdateNominee />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

// Define the AppRouter component
export default function AppRouter() {
  return useRoutes(routes);
}
