import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { changePasswordRequest } from "../features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../features/appSlice";
import { clearHasError } from "../features/userSlice";
import SuccessModal from "../components/modals/SuccessModal";

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old Password is required"),
  password: Yup.string()
    .required("New Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const successMessage = useSelector((state) => state.app.message);
  const errorMessage = useSelector((state) => state.user.hasError);

  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(changePasswordRequest(values));
    resetForm();
    setSubmitting(false);
  };

  const successAndRedirect = () => {
    localStorage.removeItem("greedAppToken");
    dispatch(setMessage(""));
    navigate("/");
  };

  const errorAndStay = () => {
    dispatch(clearHasError());
  };

  return (
    <div className="max-w-md p-6 mx-auto mt-8 bg-white rounded-md ">
      <SuccessModal
        open={successMessage}
        clickFunction={successAndRedirect}
        text={successMessage}
      />
      <SuccessModal
        open={errorMessage}
        clickFunction={errorAndStay}
        text={errorMessage}
        error
      />
      <h2 className="mb-6 text-2xl font-semibold">Change Password</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="mb-4">
            <label
              htmlFor="oldPassword"
              className="block text-sm font-medium text-gray-600"
            >
              Old Password
            </label>
            <Field
              type="password"
              id="oldPassword"
              name="oldPassword"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="oldPassword"
              component="div"
              className="text-sm text-red-500"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              New Password
            </label>
            <Field
              type="password"
              id="password"
              name="password"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-sm text-red-500"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-600"
            >
              Confirm Password
            </label>
            <Field
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="confirmPassword"
              component="div"
              className="text-sm text-red-500"
            />
          </div>

          <button
            type="submit"
            className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-400"
          >
            Change Password
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default ChangePassword;
