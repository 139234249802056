import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { updateNomineeRequest } from "../features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../features/appSlice";
import { clearHasError } from "../features/userSlice";
import SuccessModal from "../components/modals/SuccessModal";

const nomineeSchema = Yup.object().shape({
  nomineeName: Yup.string().required("Enter Nominee Name"),
  relationship: Yup.string().required("Select Relationship"),
  password: Yup.string().required("Password can't be blank"),
});

const UpdateNominee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const successMessage = useSelector((state) => state.app.message);
  const errorMessage = useSelector((state) => state.user.hasError);
  const user = useSelector((state) => state.user.userDetails);

  const initialValues = {
    nomineeName: user.nomineeName || "",
    password: "",
    relationship: user.relationship || "",
  };

  const handleSubmit = (values) => {
    dispatch(updateNomineeRequest(values));
  };

  const successAndRedirect = () => {
    localStorage.removeItem("greedAppToken");
    dispatch(setMessage(""));
    navigate("/");
  };

  const errorAndStay = () => {
    dispatch(clearHasError());
  };

  return (
    <div className="max-w-md p-6 mx-auto mt-8 bg-white rounded-md ">
      <SuccessModal
        open={successMessage}
        clickFunction={successAndRedirect}
        text={successMessage}
      />
      <SuccessModal
        open={errorMessage}
        clickFunction={errorAndStay}
        text={errorMessage}
        error
      />
      <h2 className="mb-6 text-2xl font-semibold">Update Nominee</h2>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={nomineeSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="mb-4">
            <label
              htmlFor="oldPassword"
              className="block text-sm font-medium text-gray-600"
            >
              Nominee Name
            </label>
            <Field
              autoComplete="off"
              id="nomineeName"
              name="nomineeName"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="nomineeName"
              component="div"
              className="text-sm text-red-500"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="relationship"
              className="block text-sm font-medium text-gray-600"
            >
              Relationship
            </label>
            <Field
              as="select"
              autoComplete="off"
              id="relationship"
              name="relationship"
              className="w-full p-2 mt-1 border rounded-md"
            >
              <option value="" label="Select Relationship" />
              <option value="Father" label="Father" />
              <option value="Son" label="Son" />
              <option value="Mother" label="Mother" />
              <option value="Wife" label="Wife" />
              <option value="Daughter" label="Daughter" />
              <option value="Husband" label="Husband" />
              <option value="Legal Guardian" label="Legal Guardian" />
              <option value="Other" label="Other" />
            </Field>
            <ErrorMessage
              name="relationship"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              Password
            </label>
            <Field
              autoComplete="off"
              type="password"
              id="password"
              name="password"
              placeholder="Please enter Password"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-400"
          >
            Update Now
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default UpdateNominee;
