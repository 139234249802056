import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Clock from "../../Clock";
import { useSelector } from "react-redux";
import { FaPowerOff } from "react-icons/fa6";
import Dropdown from "../../Dropdown";

const Header = () => {
  const user = useSelector((state) => state.user.userDetails);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const onLogout = () => {
    localStorage.removeItem("greedAppToken");
    navigate("/");
  };

  return (
    <div className="flex items-center justify-between px-4 py-1 text-sm text-black bg-gray-200">
      <div className="flex items-center space-x-5 cursor-pointer">
        <Link className="hover:underline" to="/dashboard">
          Dashboard
        </Link>
        <Link className="hover:underline" to="/dashboard/deposits">
          My Deposits
        </Link>
        <Link className="hover:underline" to="/dashboard/withdrawls">
          My Withdrawls
        </Link>
        <Link className="hover:underline" to="/dashboard/timeline">
          Transactions Timeline
        </Link>
        <Link className="hover:underline" to="/dashboard/withdraw-request">
          Withdraw Request
        </Link>
        <Link
          className="text-red-500 hover:underline"
          to="/dashboard/close-all-trades"
        >
          Close All Trades
        </Link>
        <Dropdown pinSet={user?.isPinSet} open={open} setOpen={setOpen} />
      </div>
      <div className="flex items-center justify-between space-x-5 ">
        <FaPowerOff
          onClick={onLogout}
          title="Logout"
          className="text-base cursor-pointer hover:text-red-500"
        />
        <Clock />
      </div>
    </div>
  );
};

export default Header;
