import axios from "axios";

export const setAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Access-control-allow-credentials"] = true;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const isWeekendInIndia = () => {
  const currentDate = new Date();
  const currentOffset = currentDate.getTimezoneOffset();
  const ISTOffset = 330; // IST offset UTC +5:30
  const ISTTime = new Date(
    currentDate.getTime() + (ISTOffset + currentOffset) * 60000
  );
  const indiaNow = new Date(ISTTime);
  const monday = indiaNow.getDay() === 1;
  const hours = indiaNow.getHours();
  const minutes1 = indiaNow.getMinutes();
  const HH = hours < 10 ? `0${hours}` : hours;
  const MM = minutes1 < 10 ? `0${minutes1}` : minutes1;
  const currentTimeNow1 = `${HH}:${MM}`;
  return (
    (indiaNow.getDay() === 6 && currentTimeNow1 > "04:30") ||
    indiaNow.getDay() === 0 ||
    (monday && currentTimeNow1 < "04:30")
  );
};

export function formatDateTime(isoDateTime) {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    new Date(isoDateTime)
  );

  return {
    date: formattedDate,
    time: formattedDate.split(", ")[1],
  };
}
