// Dashboard.js
import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";
import { setAuthorizationToken } from "../../../helpers";
import { setIsAuthenticated } from "../../../features/appSlice";
import { getUserRequest } from "../../../features/userSlice";
import Header from "./Header";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("greedAppToken");

  useEffect(() => {
    if (!token) {
      setAuthorizationToken(false);
      dispatch(setIsAuthenticated(false));
      navigate("/");
      return;
    }
    if (token) {
      const { exp } = decode(JSON.parse(token));
      if (exp * 1000 > Date.now()) {
        dispatch(setIsAuthenticated(true));
        setAuthorizationToken(JSON.parse(token));
        dispatch(getUserRequest());
      } else {
        localStorage.removeItem("greedAppToken");
        dispatch(setIsAuthenticated(false));
        setAuthorizationToken(false);
        navigate("/");
      }
    }
    // eslint-disable-next-line
  }, [token, dispatch]);
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
};

export default Dashboard;
