import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TransactionTable from "../components/TransactionTable";
import { withdrawHistoryRequest } from "../features/userSlice";

const Withdraw = () => {
  const dispatch = useDispatch();
  const withdrawTransactions = useSelector(
    (state) => state.user.withdrawHistory
  );

  useEffect(() => {
    dispatch(withdrawHistoryRequest());
  }, [dispatch]);
  return (
    <>
      <h1 className="px-4 py-2 font-semibold text-gray-800">
        Withdrawls ({withdrawTransactions?.length})
      </h1>
      <TransactionTable transactions={withdrawTransactions} />
    </>
  );
};

export default Withdraw;
