import React from "react";
import { Link } from "react-router-dom";
import { LuChevronDown } from "react-icons/lu";

const Dropdown = (props) => {
  return (
    <div
      onMouseEnter={() => {
        props.setOpen(true);
      }}
      onMouseLeave={() => {
        props.setOpen(false);
      }}
      className="relative z-40 p-1 ml-2 cursor-pointer group"
    >
      Settings <LuChevronDown className="inline group-hover:rotate-180" />
      <div
        className={`${
          props.open ? "" : "hidden"
        } absolute bg-white  border border-transparent  shadow-xl min-w-[9rem] top-6  -left-4 rounded-md text-xs `}
      >
        <Link to="/dashboard/settings/change-password">
          <p className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-blue-500 hover:text-gray-50">
            Change Password
          </p>
        </Link>
        <Link to="/dashboard/settings/update-nominee">
          <p className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-blue-500 rounded-b-md hover:text-gray-50">
            Update Nominee
          </p>
        </Link>
        {!props.pinSet && (
          <Link to="/dashboard/settings/set-pin">
            <p className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-blue-500 rounded-b-md hover:text-gray-50">
              Set PIN
            </p>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
