import React from "react";
import { MdTransitEnterexit, MdOutlineArrowOutward } from "react-icons/md";
import { formatDateTime } from "../helpers";

const TransactionTable = (props) => {
  return (
    <div className="relative m-2 overflow-x-auto border border-blue-400 shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 rtl:text-right ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="px-6 py-3">
              Description
            </th>
            <th scope="col" className="px-6 py-3">
              Date
            </th>
            <th scope="col" className="px-6 py-3">
              Amount
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {props.transactions &&
            props.transactions.length > 0 &&
            props.transactions.map((one, index) => {
              return (
                <tr
                  key={index}
                  className="border-b odd:bg-white even:bg-gray-50 "
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 capitalize whitespace-nowrap dark:text-white"
                  >
                    {one.type === "withdraw" ? (
                      <MdOutlineArrowOutward className="inline mr-1 text-2xl text-red-500" />
                    ) : (
                      <MdTransitEnterexit className="inline mr-1 text-2xl text-green-500" />
                    )}
                    {one.type}
                  </th>
                  <td className="px-6 py-4">
                    {formatDateTime(one.createdAt).date}
                  </td>
                  <td
                    className={`px-6 py-4 ${
                      one.amount > 0 ? "text-green-600" : "text-red-600"
                    } font-semibold`}
                  >
                    {one.amount}$
                  </td>
                  <td className="px-6 py-4">{one.status}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
