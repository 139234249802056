import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getLiveProfitLossRequest,
  getPartnerTransactionsRequest,
  portfolioRequest,
} from "../features/userSlice";
import TransactionTable from "../components/TransactionTable";
import { isWeekendInIndia } from "../helpers";

const MainDashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userDetails);
  const portfolio = useSelector((state) => state.user.portfolio);
  const allTransactions = useSelector((state) => state.user.myTransactions);
  const partnerBalance = useSelector((state) => state.user.partnerBalance);
  const { usedMargin, drawDown, liveProfitLoss } = useSelector(
    (state) => state.user.liveProfit
  );

  useEffect(() => {
    if (user._id) {
      dispatch(portfolioRequest());
      dispatch(getPartnerTransactionsRequest());
      setInterval(() => {
        dispatch(getLiveProfitLossRequest());
      }, 60000);
    }
  }, [dispatch, user._id]);
  return (
    <>
      <h1 className="px-4 py-2 mt-2 font-semibold text-gray-800">
        Welcome {user.fullName} !
      </h1>
      <div className="flex flex-wrap p-2 space-x-4 ">
        <div className="px-4 py-2 border border-blue-200 rounded-md">
          <p className="mb-1 text-sm text-gray-600">Current Balance</p>
          {portfolio?.equity && <p>{portfolio?.equity + portfolio?.profit}$</p>}
        </div>
        <div className="px-4 py-2 border border-blue-200 rounded-md">
          <p className="mb-1 text-sm text-gray-600">Growth</p>
          <p>{portfolio?.profit}$</p>
        </div>
        <div className="px-4 py-2 border border-blue-200 rounded-md">
          <p className="mb-1 text-sm text-gray-600">Equity</p>
          <p>{partnerBalance}$</p>
        </div>
      </div>
      <div className="relative m-2 overflow-x-auto border border-blue-400 shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 rtl:text-right ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Instrument
              </th>
              <th scope="col" className="px-6 py-3">
                Used Margin
              </th>
              <th scope="col" className="px-6 py-3">
                Draw down
              </th>
              <th scope="col" className="px-6 py-3">
                P/L
              </th>
              <th scope="col" className="px-6 py-3">
                Direction
              </th>
            </tr>
          </thead>
          <tbody>
            {!usedMargin && (
              <tr className="p-2 px-5">
                {" "}
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  fetching live data...
                </th>
              </tr>
            )}
            {usedMargin && (
              <tr className="border-b odd:bg-white even:bg-gray-50 ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {isWeekendInIndia() ? 0 : "US10Y"}
                </th>
                <td className="px-6 py-4">
                  {isWeekendInIndia() ? 0 : usedMargin}$
                </td>
                <td className="px-6 py-4">
                  {isWeekendInIndia() ? 0 : drawDown}%
                </td>
                <td
                  className={`px-6 py-4  ${
                    liveProfitLoss > 0 ? "text-green-600" : "text-red-600"
                  } font-semibold`}
                >
                  {isWeekendInIndia() ? 0 : liveProfitLoss}$
                </td>
                <td className="px-6 py-4">
                  <span className="font-medium text-green-600 hover:underline">
                    LONG
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <h1 className="px-4 py-2 font-semibold text-gray-800">
        Latest Transactions
      </h1>
      <TransactionTable transactions={allTransactions.slice(0, 5)} />
    </>
  );
};

export default MainDashboard;
