import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  getPartnerTransactionsRequest,
  makeWithdrawlRequest,
  portfolioRequest,
} from "../features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../features/appSlice";
import { clearHasError } from "../features/userSlice";
import SuccessModal from "../components/modals/SuccessModal";

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string().required("Enter PIN"),
  accountName: Yup.string().required("Please enter Account Name"),
  accountNumber: Yup.string().required("Please enter Account Number"),
  ifsc: Yup.string().required("Please enter IFSC Code"),
  contact: Yup.string().required("Please enter Contact Number"),
  bankName: Yup.string().required("Please enter Bank Name"),
});

const WithdrawRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const successMessage = useSelector((state) => state.app.message);
  const user = useSelector((state) => state.user.userDetails);
  const errorMessage = useSelector((state) => state.user.hasError);
  const portfolio = useSelector((state) => state.user.portfolio);

  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to check if the input is a positive integer
    const isValidInput = /^\d+$/.test(inputValue);
    if (isValidInput) {
      setAmount(Number(inputValue));
    }
  };

  const initialValues = {
    password: "",
    accountName: "",
    accountNumber: "",
    ifsc: "",
    contact: "",
    bankName: "",
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // dispatch(changePasswordRequest(values));
    const { password, accountName, accountNumber, ifsc, contact, bankName } =
      values;

    dispatch(
      makeWithdrawlRequest({
        amount,
        password,
        bankDetails: {
          accountName,
          accountNumber,
          ifsc,
          contact,
          bankName,
        },
      })
    );
    setSubmitting(false);
  };

  const successAndRedirect = () => {
    dispatch(setMessage(""));
    navigate("/dashboard/main");
  };

  const errorAndStay = () => {
    dispatch(clearHasError());
  };

  useEffect(() => {
    if (user._id) {
      dispatch(portfolioRequest());
      dispatch(getPartnerTransactionsRequest());
    }
  }, [dispatch, user._id]);

  return (
    <div className="max-w-sm p-6 mx-auto mt-8 bg-white rounded-md ">
      <SuccessModal
        open={successMessage}
        clickFunction={successAndRedirect}
        text={successMessage}
      />
      <SuccessModal
        open={errorMessage}
        clickFunction={errorAndStay}
        text={errorMessage}
        error
      />
      <h2 className="mb-6 text-2xl font-semibold">Make Withdraw Request</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="max-w-sm my-4">
            <label
              htmlFor="numberInput"
              className="block text-sm font-medium text-gray-600"
            >
              Enter Amount
            </label>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              value={amount}
              onChange={handleNumberChange}
              className="w-full p-2 mt-1 border rounded-md"
            />
            {portfolio?.equity + portfolio?.profit && (
              <p className="mt-1 text-sm text-gray-500">
                Max Withdrawable Amount: {portfolio?.equity + portfolio?.profit}
                $
              </p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              FUND PIN
            </label>
            <Field
              type="password"
              id="password"
              name="password"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-sm text-red-500"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="bankName"
              className="block text-sm font-medium text-gray-600"
            >
              Bank Name
            </label>
            <Field
              id="bankName"
              name="bankName"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="bankName"
              component="div"
              className="text-sm text-red-500"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="accountName"
              className="block text-sm font-medium text-gray-600"
            >
              Account Name
            </label>
            <Field
              id="accountName"
              name="accountName"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="accountName"
              component="div"
              className="text-sm text-red-500"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="accountNumber"
              className="block text-sm font-medium text-gray-600"
            >
              Account Number
            </label>
            <Field
              id="accountNumber"
              name="accountNumber"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="accountNumber"
              component="div"
              className="text-sm text-red-500"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="ifsc"
              className="block text-sm font-medium text-gray-600"
            >
              IFSC CODE
            </label>
            <Field
              id="ifsc"
              name="ifsc"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="ifsc"
              component="div"
              className="text-sm text-red-500"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="contact"
              className="block text-sm font-medium text-gray-600"
            >
              Contact
            </label>
            <Field
              id="contact"
              name="contact"
              className="w-full p-2 mt-1 border rounded-md"
            />
            <ErrorMessage
              name="contact"
              component="div"
              className="text-sm text-red-500"
            />
          </div>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-400"
          >
            Make Request
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default WithdrawRequest;
