import React, { useEffect } from "react";
import * as yup from "yup";
import { MdWarning } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest, clearHasError } from "../features/userSlice";
import SuccessModal from "../components/modals/SuccessModal";
import { Formik, Field, Form } from "formik";
import bgImage from "../bg.jpg";
import logo from "../logo.svg";

const LoginComp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);
  const errorMessage = useSelector((state) => state.user.hasError);
  const loading = useSelector((state) => state.app.isLoading);

  const loginSchemaValidation = yup.object({
    username: yup.string().required("User Name can't be blank"),
    password: yup.string().required("Password can't be blank"),
  });

  const onSubmit = (values, { resetForm }) => {
    dispatch(loginRequest(values));
    resetForm({ values: "" });
  };

  const errorAndStay = () => {
    dispatch(clearHasError());
  };

  useEffect(() => {
    isAuthenticated && navigate("dashboard");
  }, [isAuthenticated, navigate]);

  return (
    <section
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
      }}
      className="pt-20"
    >
      <div className="flex flex-col max-w-xs p-4 mx-auto text-gray-600 bg-white border border-transparent rounded-md sm:p-6 lg:border-gray-400">
        <div className="mb-2 text-center">
          <img src={logo} className="mx-auto" alt="greed managers logo" />
          <p className="my-2 text-sm text-gray-700">
            Sign in to access your account
          </p>
        </div>

        <SuccessModal
          open={errorMessage}
          clickFunction={errorAndStay}
          text={errorMessage}
          error
        />
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={loginSchemaValidation}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form className="space-y-8">
              <div className="space-y-4">
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm">
                    Username
                  </label>
                  <Field
                    className={`${
                      errors.username && touched.username
                        ? "border-red-500"
                        : "border-gray-300"
                    } form-control text-base block w-full px-4 py-2  font-normal text-gray-700 bg-white bg-clip-padding border border-solid  rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
                    name="username"
                    placeholder="Enter User ID"
                  />
                  {errors.username && touched.username ? (
                    <div className="flex items-center justify-start mt-2 ml-1 text-sm text-red-500">
                      <MdWarning className="mr-1" />
                      {errors.username}
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className="flex justify-between mb-2">
                    <label htmlFor="password" className="text-sm">
                      Password
                    </label>
                  </div>
                  <Field
                    type="password"
                    className={`${
                      errors.password && touched.password
                        ? "border-red-500"
                        : "border-gray-300"
                    } form-control block w-full px-4 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid  rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
                    placeholder="Password"
                    name="password"
                  />
                </div>
              </div>
              <div className="space-y-1">
                <button
                  type="submit"
                  className="w-full px-8 py-2 font-semibold text-gray-100 rounded-md bg-theme-green"
                >
                  {loading && (
                    <svg
                      className="inline w-5 h-5 mr-3 -ml-2 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  <span>{loading ? "Processing" : "Login"}</span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default LoginComp;
