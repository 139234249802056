import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TransactionTable from "../components/TransactionTable";
import { depositHistoryRequest } from "../features/userSlice";

const Deposit = () => {
  const dispatch = useDispatch();
  const depositTransactions = useSelector((state) => state.user.depositHistory);

  useEffect(() => {
    dispatch(depositHistoryRequest());
  }, [dispatch]);
  return (
    <>
      <h1 className="px-4 py-2 font-semibold text-gray-800">
        Deposits ({depositTransactions?.length})
      </h1>
      <TransactionTable transactions={depositTransactions} />
    </>
  );
};

export default Deposit;
