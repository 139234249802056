import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  closeAllTradesRequest,
  getPartnerTransactionsRequest,
  portfolioRequest,
} from "../features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../features/appSlice";
import { clearHasError } from "../features/userSlice";
import SuccessModal from "../components/modals/SuccessModal";

const CloseAllTrades = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [warningModal, setWarningModal] = useState("");
  const successMessage = useSelector((state) => state.app.message);
  const errorMessage = useSelector((state) => state.user.hasError);

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue) {
      setPassword(inputValue);
    }
  };

  const openWarningModal = () => {
    setWarningModal(true);
  };

  const finalFunction = () => {
    if (password) {
      dispatch(closeAllTradesRequest({ password }));
    }
    setWarningModal(false);
  };

  const successAndRedirect = () => {
    dispatch(setMessage(""));
    navigate("/dashboard/main");
  };

  const errorAndStay = () => {
    dispatch(clearHasError());
  };

  return (
    <div className="max-w-sm p-6 mx-auto mt-8 bg-white rounded-md ">
      <SuccessModal
        open={successMessage}
        clickFunction={successAndRedirect}
        text={successMessage}
      />
      <SuccessModal
        open={errorMessage}
        clickFunction={errorAndStay}
        text={errorMessage}
        error
      />

      <h2 className="mb-6 text-2xl font-semibold text-red-500">
        Close All Trades
      </h2>
      <p className="text-red-600">
        Destructive Action : By clicking below button you requested to close all
        trades. This action will lead to some panelty charges due to emergency
        trade loss. <br /> Please Be Mindful
      </p>
      <div className="max-w-sm my-4">
        <label
          htmlFor="numberInput"
          className="block text-sm font-medium text-gray-600"
        >
          Enter PIN
        </label>
        <input
          id="password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
          className="w-full p-2 mt-1 border rounded-md"
        />
      </div>
      <SuccessModal
        open={warningModal}
        clickFunction={() => setWarningModal(false)}
        text="Yes I'm sure , I know what I'm doing, I'll bear following trade loss charges."
        error
        buttonText="Yes ! STOP ALL TRADES NOW"
        buttonFunction={finalFunction}
      />
      <div className="mt-4">
        {password && (
          <button
            type="button"
            onClick={openWarningModal}
            className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-gray-400"
          >
            Proceed to CLOSE ALL TRADES
          </button>
        )}
      </div>
    </div>
  );
};

export default CloseAllTrades;
